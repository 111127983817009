import React, { useEffect } from 'react';
import styles from "../styles/atsymbol.module.css";
import { atScroll} from "../Utils/anims.js";
// import { useEffect } from "react";

export const Svg = () => {
	useEffect(() => {
		 atScroll();
		// scrollTeller();
	  }, [])

	return (
		<svg width={100} height={100} xmlns="http://www.w3.org/2000/svg" className={styles.svg} id={"at"}>
			<path d="m 33.33 50 a 0.5 0.5 0 0 1 0 -33.33 l 6.67 0 l 0 -3.33 l 0 30 a 1 1 0 0 0 23.33 0 l 0 -10 a 1 1 0 0 0 -60 0 a 33.33 33.33 0 0 0 30 30" stroke="white" strokeWidth="6.67" fill="none" strokeLinecap="round"></path>
		</svg>


		// <svg width={300} height={300}
		// 	xmlns="http://www.w3.org/2000/svg" className={styles.svg} id={"at"}>
		// 	<path d="m 100 150 a 0.5 0.5 0 0 1 0 -100 l 20 0 l 0 -10 l 0 90 a 1 1 0 0 0 70 0 l 0 -30 a 1 1 0 0 0 -180 0 a 100 100 0 0 0 90 90" stroke="white" strokeWidth="20px" fill="none" strokeLinecap="round"></path>
		// </svg>
	)
}
