import React, { useState, useEffect } from 'react';
//import logo from './assets/logo.webp';

//import header_img from './assets/header_img.jpg';

import wolfram from './assets/General_Catalyst.webp';
//import vasi from './assets/vasi.webp';
import echo3d from './assets/GlobalLogic.webp';
import kls from './assets/kls.webp';
import Pagetop from './components/pagetop.js';

import { Svg } from "./components/atsymbol.js";

//import { navScroll }  from "./Utils/anims.js";

// import photo2 from './assets/2.webp';
// import photo3 from './assets/3.webp';
// import photo4 from './assets/4.webp';
// import photo5 from './assets/5.webp';
// import photo6 from './assets/6.webp';
// import photo7 from './assets/7.webp';
// import photo8 from './assets/8.webp';
// import photo9 from './assets/9.webp';

import './App.css';

const Typewriter = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // navScroll();
    const interval = setInterval(() => {
      if (index < text.length) {
        if (text[index] === '<') {
          // If the current character is '<', find the index of '>'
          const endIndex = text.indexOf('>', index);
          // Extract the substring containing the HTML tag
          const tag = text.substring(index, endIndex + 1);
          setDisplayText((prevText) => prevText + tag);
          setIndex(endIndex + 1);
        } else {
          // Otherwise, append the character to the displayed text
          setDisplayText((prevText) => prevText + text[index]);
          setIndex((prevIndex) => prevIndex + 1);
        }
      } else {
        clearInterval(interval);
      }
    }, 100); // Adjust the interval (in milliseconds) to control the typing speed

    return () => clearInterval(interval);
  }, [index, text]);

  return <span dangerouslySetInnerHTML={{ __html: displayText }} />;
};



const AnimatedText = () => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    // Show the text after a delay of 2 seconds
    const timer = setTimeout(() => {
      setShowText(true);
    }, 2000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="animation-container">

     
        <div className="row">
          <div className="svg-container">
            <Svg />
          </div>
          <div className="text-container">
            {showText && (
              <h1 className="mb-8 text-5xl font-bold md:text-6xl lg:text-7xl slide-down">
                KLS Pitch Day
              </h1>
            )}
          </div>
        </div>
     


      {/* <div>
        <div>
          <Svg />
        </div>
        <div>
          {showText && (
            <h1 className="mb-4 text-5xl font-bold md:text-6xl lg:text-7xl slide-down">
              KLS Pitch Day
            </h1>
          )}
        </div>

      </div> */}
    </div>
  );
}


function App() {
  return (
    <div className="App">


      <main>

        <Pagetop></Pagetop>
        <section className="w-screen">


          <div className="mt-6 w-screen flex justify-center items-center flex-col gap-2 md:backdrop-brightness-50 text-white padding-bottom-100">

            {/* <img src={logo} width="150" height="125" decoding="async" data-nimg="1" className="mb-4" loading="lazy" alt="" /> */}
            {/* <h1 className="mb-4 text-5xl font-bold md:text-6xl lg:text-7xl">@ KLS Pitch Day</h1> */}

            <AnimatedText />

            <div className="mb-4 text-center text-xl md:text-2xl lg:text-3xl">
              <div className="Typewriter" data-testid="typewriter-wrapper">
                <Typewriter text="June 1st 2024, Mountain View CA" />
                <span className="blinking-line">|</span></div>
            </div>
            <div className="flex justify-center items-center gap-3 mb-4 h-auto">
              <a href="mailto:klspitchday@gmail.com" target="_blank" rel="noreferrer" aria-label="Email">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="48" width="48" xmlns="http://www.w3.org/2000/svg">
                  <path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path>
                </svg>
              </a>
            </div>
            <a className="mt-6 p-4 border-4 rounded-xl border-black dark:border-white hover:bg-black hover:dark:bg-white hover:text-white hover:dark:text-black" target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSewkjnipuzGpCeGYhxzbKtyBW4ROZKbUCaBlGyFppfrvOnbpA/viewform?usp=sf_link">
              <p className="text-2x md:text-3xl lg:text-4xl register_now">Register now!</p>
            </a>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 512" className="mt-4" height="64" width="64" xmlns="http://www.w3.org/2000/svg">
              <path d="M168 345.941V44c0-6.627-5.373-12-12-12h-56c-6.627 0-12 5.373-12 12v301.941H41.941c-21.382 0-32.09 25.851-16.971 40.971l86.059 86.059c9.373 9.373 24.569 9.373 33.941 0l86.059-86.059c15.119-15.119 4.411-40.971-16.971-40.971H168z"></path>
            </svg>
          </div>
        </section>
        <section className="w-screen bg-yellow  flex items-center justify-center">
          <div className="w-full md:w-3/4 h-fit md:mb-16 flex flex-col gap-8 items-center justify-center pt-8 px-2 md:px-20">
            <div className="bg-yellow  px-4 py-2.5 flex flex-col justify-center items-center">
              <h2 className="text-center text-xl md:text-2xl lg:text-3xl">About</h2>

              <p className="font-sans p-4 rounded-xl abt">
                To inspire innovation and entrepreneurship among students, the Entrepreneurship & Innovation (E & I) <br /> Club at Khan Lab School is organizing the first ever Pitch Day contest at KLS on Saturday, June 1st 2024.
              </p>
            </div>
            <div className="bg-yellow  px-4 py-2.5 flex flex-col justify-center items-center">

              <h2 className="text-xl md:text-2xl lg:text-3xl">What is Pitch Day?</h2>
              <p className="font-sans p-4 rounded-xl abt">It's a day when aspiring student entrepreneurs present their innovative ideas, products or solutions to a panel <br /> of investors and entrepreneurs! The top pitches will receive monetary awards to help build their ideas.</p>
            </div>

            <div className="bg-yellow  px-4 py-2.5 flex flex-col">
              <h2 className="mb-4 text-xl md:text-2xl lg:text-3xl items-center justify-center">Eligibility & Contest Rules:</h2>

              <ul className="font-sans rules items-left">
                <li>Open to any HS student from KLS or other schools.</li>
                <li>Participate individually or as a team of upto 4 students.</li>
                <li>Live Pitch your idea for 4-6 minutes followed by Q & A from judges/audience.</li>
                <li>Your idea can be a for-profit, non-profit or a project that benefits numerous people or the world around us.</li>
                <li>Student makers can also pitch their creative products for an entrepreneurial venture.</li>
                <li>No prior experience or incorporation required! </li>
                <li>Your pitch should be business-like and the dress code is smart casual.</li>
              </ul>

            </div>
            {/* <div className="bg-yellow  px-4 py-2.5 flex flex-col justify-center items-center">
              <p className="font-sans p-4 dark:bg-white dark:text-black rounded-xl abt">To get event updates, you can pre-register <a href="https://docs.google.com/forms/d/e/1FAIpQLSewkjnipuzGpCeGYhxzbKtyBW4ROZKbUCaBlGyFppfrvOnbpA/viewform?usp=sf_link" target="_blank" rel="noreferrer" className="underline">here</a>.</p>
            </div> */}

            <div className="bg-yellow  px-4 py-2.5 flex flex-col justify-center items-center">
              <h2 className="mb-4 text-center text-xl md:text-2xl lg:text-3xl">Sponsors</h2>
              <div className="grid grid-cols-3 p-4 gap-8 items-center justify-center rounded-xl">
                <img src={wolfram} width="300" height="236" decoding="async" data-nimg="1" className="w-full" loading="lazy" alt="" />
                <img src={kls} width="5437" height="2356" decoding="async" data-nimg="1" className="w-full" loading="lazy" alt="" />
                <img src={echo3d} width="1255" height="775" decoding="async" data-nimg="1" className="w-full" loading="lazy" alt="" />


              </div>
            </div>
            <div className="bg-yellow  px-4 py-2.5">&nbsp;</div>

          </div>
        </section>
        <footer className="w-screen text-center bg-yellow fixed bottom-0 py-4 md:block md:text-lg lg:text-xl">© {new Date().getFullYear()} KLS Pitch Day. All Rights Reserved.</footer>
      </main>

    </div>
  );
}

export default App;
