import React, { useEffect } from 'react';
// import { TweenMax, Power2 } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

// import '../styles/global.css';
// import styles from "../styles/pagetop.module.css";// Assuming you're using CSS modules

// export default function Pagetop() {
//   useEffect(() => {
//     const animateColors = () => {
//       TweenMax.to('.' + styles.h1, 1, { color: 'yellow', ease: Power2.easeInOut });
//       TweenMax.to('.' + styles.h1, 1, { color: 'white', ease: Power2.easeInOut, delay: 1 });
//     };

//     // Animate colors continuously
//     animateColors();
//     setInterval(animateColors, 2000); // Repeat the animation every 2 seconds
//   }, []);

//   return (
//     <section className={styles.section}>
//       <h1 className={`${styles.h1} ${styles.bebasneue}`} id="striped">Innovate</h1>
//     </section>
//   );
// }



import '../styles/global.css';
import styles from "../styles/pagetop.module.css";
import { stripeScroll, mouseScroll } from "../Utils/anims.js";
import header_img from '../assets/header_img.jpg';
//, atScroll, scrollTeller 

export default function Pagetop() {
  useEffect(() => {
    stripeScroll();
    mouseScroll();
    // atScroll();
    // scrollTeller();
  }, [])
  return (
    <section className={styles.section}>
      <div className="md:block md:w-screen md:h-screen md:fixed md:-z-10">
        <img src={header_img} sizes="100vw" className="header-bg" alt="" />
      </div>
      <h6 className={`${styles.roboto} ${styles.simpletext}`} id={"teller"}>&nbsp;</h6>
      <h1 className={`${styles.h1} ${styles.bebasneue}`} id={"striped"}>Innovate</h1>
    </section>
  );
}
